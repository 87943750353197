import React from 'react';
import { createRoot } from 'react-dom/client';
import WebPages from './WebPages';
import './index.css';
import Banner from './Components/Banner';

function App() {
    return (
    <React.StrictMode>
        <div style={{ margin: '-8px' }}>
            <Banner />
            <WebPages />
        </div>
    </React.StrictMode>
    );
};

const root = createRoot(document.getElementById('root')!);
root.render(<App />);