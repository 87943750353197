import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MainPage from './Pages/MainPage';
import Rules from './Pages/Rules';
import Contact from './Pages/Contact';

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/rules",
    element: <Rules />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
]);

export default function WebPages() {
    return <RouterProvider router={router} />;
}
