import React from 'react';
import InfoCard, { CardText } from '../Components/InfoCard';
import Page from '../Components/Page';

export default function Contact() {
    return (
        <Page>
            <InfoCard title='Committee'>
                <CardText>
                    <p>Chairman: Guido de Hart</p>
                    <p>Secretary: Guusje van Leersum</p>
                    <p>Treasurer: Sophie Rijkers</p>
                    <p>Chief Balloon Filler: Naut de Vroome</p>
                    <p>Domjudge Master: Tim Brands</p>
                    <p>Promotion: Sophie van der Dong</p>
                    <p>Board Representative: Tristan van Beurden</p>
                    <p>Candidate board representative: Thijs Krosman</p>
            </CardText>
            </InfoCard>
            <InfoCard title='Contact'>
                <CardText>
                    <p>Email: tapc@inter-actief.net </p>
                </CardText>
            </InfoCard>
        </Page>
    );
}
