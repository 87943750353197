import React from 'react';
import InfoCard, { CardText } from '../Components/InfoCard';
import Page from '../Components/Page';

export default function MainPage() {
    return (
        <Page>
            <InfoCard title='About'>
                <CardText>
                    Do you want to put your programming skills to the test, or are you good at math?
                    Do you want to compete with other teams for the best and fastest solutions?
                    Enroll in the TAPC with a team consisting of one to three members and take on the challenge!
                </CardText>
                <CardText>
                    You will be writing algorithms for 6-10 problems, for which you will be given 5 hours.
                    You can win great prizes and will be allowed to partake in the BAPC where the challenge goes international.
                </CardText>
            </InfoCard>
            <InfoCard title='Date'>
                <CardText>
                    The date of TAPC 2024 is set to be the 28th of September 2024!
                </CardText>
            </InfoCard>
            <InfoCard title='Participation'>
                <CardText>
                    In order to participate in the Twents Algorithm Programming
                    Contest (TAPC), you have to enroll with a team of two to
                    three people. All members of a team have to be enrolled in
                    the same educational institute if the team wants to
                    participate as an official team. It is possible to
                    participate as an unofficial team, for example, university
                    staff could participate in an unofficial team. The
                    competition involves solving six to ten exercises. The goal
                    of the competition is to solve them as quickly and
                    efficiently as possible.
                </CardText>
                <CardText>
                    The winner will be announced after the competition is over.
                    The winning official team (and sometimes more teams) can
                    join the{' '}
                    <a
                        rel='noopener noreferrer'
                        target='_blank'
                        href='http://bapc.eu'
                    >
                        Benelux Algorithm Programming Contest
                    </a>
                    , representing Twente. Participation in the TAPC is a
                    hundred percent free.
                </CardText>
            </InfoCard>

            <InfoCard title="Afterdrink">
                <CardText>
                    After the TAPC there is an afterdrink in Abscint.
                    It will also possible to order food. Sign ups for dinner will open soon and can be found here and on the Inter-<i>Actief</i> website!
                    {/* <a
                      rel='noopener noreferrer'
                      target='_blank'
                      href='https://www.inter-actief.utwente.nl/activities/7439/'
                    >
                      here
                    </a>
                    . */}
                    {/* Unfortunately ordering food is only possible for Inter-<i>Actief</i>-members. */}
                </CardText>
            </InfoCard>

            <InfoCard title='Registration'>
                <CardText>
                    In order to participate in the TAPC you need to register
                    with every member of your team individually.
                    This can be done in two ways. If you are a member of Inter-<i>Actief</i> you
                    can use the{' '}
                    <a
                      rel='noopener noreferrer'
                      target='_blank'
                      href='https://www.inter-actief.utwente.nl/activities/7439/'
                    >
                      event page
                    </a>
                    . Otherwise please fill in <a
                      rel='noopener noreferrer'
                      target='_blank'
                      href='https://docs.google.com/forms/d/e/1FAIpQLSeuZhN7uZXjtnxKruIZzr5ssOKpOLESYsF41VQfcN3AfkKgAA/viewform?usp=sf_link'
                    >this form</a>.
                </CardText>
            </InfoCard>

            <InfoCard title='Location'>
                <CardText>
                    The TAPC will be organized in the Smart XP. For more
                    detailed instructions on how to get there please check out{' '}
                    <a
                        rel='noopener noreferrer'
                        target='_blank'
                        href='https://www.utwente.nl/en/eemcs/smartxp/'
                    >
                        this link.
                    </a>
                </CardText>
            </InfoCard>
            <InfoCard title='Tools'>
                <CardText>
                  <p>The allowed programming languages will be announced later</p>
                    {/* <p>
                        The following languages will be available during the
                        contest:
                    </p>
                    <ul>
                        <li>C</li>
                        <li>C++</li>
                        <li>Java</li>
                        <li>Python 3</li>
                    </ul> */}
                </CardText>
            </InfoCard>
            {/* <InfoCard title='Programme'>
                <CardText>
                    <Table>
                        <Table.Head>
                            <Table.TextHeaderCell>Time</Table.TextHeaderCell>
                            <Table.TextHeaderCell>
                                Activity
                            </Table.TextHeaderCell>
                        </Table.Head>
                        <Table.Body>
                            <Table.Row>
                                <Table.TextCell>11:00</Table.TextCell>
                                <Table.TextCell>
                                    Test setup, with the test question
                                </Table.TextCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.TextCell>11:45</Table.TextCell>
                                <Table.TextCell>Welcome speech</Table.TextCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.TextCell>12:00</Table.TextCell>
                                <Table.TextCell>
                                    Competition starts
                                </Table.TextCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.TextCell>17:00</Table.TextCell>
                                <Table.TextCell>
                                    End of competition - Start of drink
                                </Table.TextCell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                </CardText>
            </InfoCard> */}
        </Page>
    );
}
